@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

$primary: #ffc20e;
$secondary: #c29204;

/* ***** AKAD ****** */
/* 
$primary: #e9256a;
$secondary: #c21351; 
*/

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif !important;
}

#root {
  width: 100vw;
  height: 100vh;
  display: flex;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.user-select-none {
  user-select: none;
}

@import 'node_modules/bootstrap/scss/bootstrap';
@import 'views/formulario', 'views/start', 'views/end', 'components/paginator', 'components/input', 'components/autosuggest',
  'components/bottom_sheet', 'components/radio', 'components/modal', 'components/react_select';

::placeholder {
  font-weight: 500 !important;
  color: gray('400') !important;
}
