.react-select {
  outline: initial !important;
  box-shadow: none !important;
}

.react-select__value-container {
  outline: initial !important;
  box-shadow: none !important;
  padding: 0.45rem 0.75rem 0.4rem 0.75rem !important;
  background: white !important;
}

.react-select .react-select__dropdown-indicator {
  color: lightgray;
}

.react-select .react-select__menu-list {
  padding-bottom: 0;
  padding-top: 0;
}

.react-select .react-select__single-value,
.react-select .react-select__multi-value__label {
  color: #495057;
}

.react-select .react-select__dropdown-indicator,
.react-select .react-select__control--is-focused .react-select__dropdown-indicator,
.react-select .react-select__clear-indicator,
.react-select .react-select__control--is-focused .react-select__clear-indicator {
  outline: initial;
  box-shadow: initial;

  &:active,
  &:focus,
  &:hover {
    color: lightgray !important;
  }
}

.react-select__control {
  border-radius: $border-radius !important;
  border: none !important;
  background: white !important;
  outline: initial !important;
  box-shadow: none !important;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__dropdown-indicator svg {
  width: 15px;
  height: 15px;
}

.react-select__option {
  background: white !important;
  color: black !important;

  &:hover,
  &:active {
    background: lightgray !important;
    color: black !important;
  }
}

.react-select__option--is-selected {
  background: lightgray !important;
  color: #495057 !important;
}

.react-select__control--is-focused {
  border-color: rgba(lightgray, 0.6) !important;
}

.react-select__multi-value {
  background: transparent !important;
  border: 1px solid gray !important;
}

.react-select__multi-value__remove:hover,
.react-select__multi-value__remove:active {
  background: transparent !important;
  color: lightgray !important;
}

.react-select .react-select__menu {
  border-radius: $border-radius !important;
  z-index: 20 !important;
  box-shadow: initial !important;
  border: 1px solid rgba(lightgray, 0.6) !important;
  border-top: initial !important;
  margin-top: -1px !important;
  background-color: white !important;
  border-width: 1px !important;
}

.react-select__option—is-focused {
  background: lightgray !important;
  color: #495057 !important;
}

.react-select__single-value {
  bottom: 0 !important;
  top: 50% !important;
  padding-top: 2px !important;
}
