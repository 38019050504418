.c_bottom_sheet {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  overflow: hidden;
  background: #00000022;

  & > div {
    bottom: 0;
    width: 100%;
    max-height: 100%;
    display: flex;
    overflow: auto;
    position: absolute;
    flex-direction: column;
    background-color: white;
    transform: translateY(100%);
  }

  .title {
    margin: 0;
    line-height: 1;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
  }

  .icon {
    right: $spacer;
    position: absolute;
  }
}
