.c_input {
  .input {
    border: solid 1px whitesmoke;
  }
  .label {
    width: 100%;
    font-size: 0.8rem;
  }

  .input_alt {
    @extend .input;
    @extend .user-select-none;
    height: auto;
    font-weight: 200;

    &[disabled] {
      @extend .form-control:disabled;
    }

    .clear {
      border: none;
      background-color: transparent;

      &:focus,
      &:invalid {
        box-shadow: none;
      }
      &:disabled {
        background-color: transparent;
      }
    }
  }

  .loading {
    top: 40%;
    bottom: 0;
    position: absolute;
    right: $spacer * 0.5;
  }
}
