.formulario {
  &.container {
    flex: 1;
    margin: 0;
    display: flex;
    max-width: unset;
    flex-direction: column;
    overflow: hidden;
    background-color: #2c3a39;
  }

  .rc-slider-step {
    background: #00204e;
  }

  .spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;

    margin: -10px 0 0 -15px;
    width: 30px;
    height: 30px;

    & .path {
      stroke: hsl(210, 70, 75);
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }

  .plan_card {
    background: #2c3a39;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .separator {
      height: 1px;
      width: 200px;
      background-color: #e8e8e8;
    }

    .title {
      color: $primary;
    }

    .coberturas {
      .item_cobertura {
        display: flex;
        align-items: center;

        p {
          font-size: 12px;
        }
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: #2c3a39;
    color: white;

    .circulo {
      width: 1rem;
      height: 1rem;
      border-radius: 0.5rem;
      margin: 0 0.2rem 0.7rem 0.2rem;
      background-color: gray('300');
    }

    .ativo {
      width: inherit;
      height: inherit;
      border-radius: inherit;
      background-color: $primary;
    }
  }

  .content {
    .group-row {
      display: flex;

      .form-group {
        flex: 1;
      }
    }

    .wrapper_banner {
      width: $spacer * 12;
      height: $spacer * 18;
      display: flex;
      align-items: center;
      flex-direction: column;
      background-color: $primary;
      box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);

      .wrapper_qr {
        flex: 1;
        display: flex;
        align-self: stretch;
        align-items: center;
        justify-content: center;
        background-color: white;
      }
    }

    .wrapper-icon {
      width: auto;
      height: 50%;
      background-color: $primary;
      margin: -$spacer $spacer * -1.5 0 $spacer * -1.5;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .categoria {
      display: flex;
      flex-direction: column;
      box-shadow: 0px 0px 5px 0.5px rgba(0, 0, 0, 0.1);

      .label {
        width: 100%;
        font-size: 0.8rem;
      }
    }

    .modalidade {
      display: flex;
      font-size: 0.8rem;
      font-weight: bold;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0px 0px 5px 0.5px rgba(0, 0, 0, 0.1);
    }

    .coberturas {
      .title {
        font-size: 0.8rem;
        font-weight: bold;
      }

      .descricao {
        font-size: 0.8rem;
      }

      .mes {
        font-size: 0.6rem;
        color: gray('500');
      }

      .check {
        width: 3rem;
        flex-shrink: 0;
        align-self: stretch;
        background-color: $primary;
        justify-content: center;
        align-items: center;
      }

      .slider-text {
        font-size: 0.8rem;
        color: $primary;
        font-weight: bold;
      }
    }

    .resumo {
      .separator {
        height: 0.5px;
        width: 100%;
        background-color: #dbdbdb;
      }

      .text {
        font-size: 0.9rem;
      }

      .suffix {
        font-size: 0.8rem;
        color: gray('500');
        margin-bottom: 0;
      }

      .links {
        width: 100%;

        .separator {
          height: 1px;
          width: 100%;
          background-color: red !important;
        }

        .text_link {
          color: $primary;
          cursor: pointer;
        }
      }
    }
  }

  .overflow {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
    align-items: center;
    justify-content: center;
    background-color: white;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2c3a39;

    .btn {
      border-radius: 30px;
      font-size: 12px !important;
      padding: 10px 40px;
    }
  }
}
